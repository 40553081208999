import React, { useEffect, useState } from "react"
import { getRandomBoolean } from "../../utils/random"

const PER_ROW = 14
const MAX_ROWS = 9

const INTERVAL = (1000 * PER_ROW) / 10

const makeRow = rowId => {
  return {
    rowId,
    people: Array.from({ length: PER_ROW }, (_, i) => getRandomBoolean(0.3)),
  }
}

const Person = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.5 48.2">
      <path
        d="M35.5 48.2H1.1c-.6 0-1.1-.5-1.1-1.1v-3.2c0-3.8 1.1-7.3 3.7-10.2 2.8-3.3 6.4-5 10.7-5.2 2.8-.1 5.6-.1 8.4.1 6.5.5 12 5.4 13.2 11.9.4 2.1.4 4.4.5 6.6.1.6-.4 1.1-1 1.1zm-29-35.8c-.1-6 4-11.2 9.7-12.2C21.5-.8 27 2.4 29 7.7s.4 11.1-4 14.6c-4.3 3.5-10.9 3.1-14.8-.9-2.4-2.5-3.6-5.4-3.7-9z"
        className={`person ${props.isSelected ? "is-selected" : ""}`}
      />

      {props.isSelected && (
        <path
          className="star"
          stroke="#fff"
          fill="transparent"
          d="m31.17 14.98.03.04a1.79 1.79 0 0 1-.86 2.66l-9.06 3.3-.22.07-.08.22-3.3 9.07a1.79 1.79 0 0 1-3.35 0l-3.3-9.07-.08-.22-.22-.08-9.06-3.29A1.77 1.77 0 0 1 .5 16.01a1.79 1.79 0 0 1 1.17-1.68l9.07-3.3.22-.08.08-.22 3.28-9.06A1.77 1.77 0 0 1 16 .5a1.79 1.79 0 0 1 1.67 1.17l3.3 9.07.08.22.22.08 9.06 3.28c.34.13.64.36.84.66Z"
        />
      )}
    </svg>
  )
}

const AudienceSection = () => {
  const [rows, setRows] = useState(
    Array.from({ length: MAX_ROWS }, (_, i) => makeRow(MAX_ROWS - i - 1))
  )
  const [rowId, setRowId] = useState(MAX_ROWS - 1)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRowId(rowId => rowId + 1)
      setRows(rows => [makeRow(rowId + 1), ...rows].slice(0, MAX_ROWS))
    }, INTERVAL)
    return () => clearTimeout(timeout)
  }, [rows])

  return (
    <div className="audience-section has-background-dark">
      <div className="infinite-grid">
        {rows.map(row => (
          <div
            className="row"
            key={row.rowId}
            style={{
              marginLeft: -1.5 * (row.rowId % 2) + "em",
              transform: `translateY(${Math.min(
                0,
                -((MAX_ROWS - row.rowId - 1) * 3) // so the animation doesn't start empty
              )}em)`,
            }}
          >
            {row.people.map((isSelected, i) => (
              <Person key={row.rowId + "." + i} isSelected={isSelected} />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AudienceSection
