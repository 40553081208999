import React from "react"

import Star from "../../images/star.svg"
import DV360 from "../../images/channel-logos/dv360.svg"
import Taboola from "../../images/channel-logos/taboola.svg"
import GoogleAds from "../../images/channel-logos/google-ads.svg"
import Outbrain from "../../images/channel-logos/outbrain.svg"
import RevContent from "../../images/channel-logos/revcontent.svg"
import MgId from "../../images/channel-logos/mgid.svg"
import arrow from "../../images/arrow.svg"

import { Link } from "gatsby"

const TransitionArrow = () => {
  return (
    <div className="arrow column is-1 is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
      <img src={arrow}></img>
    </div>
  )
}

const images = [
  { alt: "DV360", src: DV360 },
  { alt: "Taboola", src: Taboola },
  { alt: "Google", src: GoogleAds },
  { alt: "Outbrain", src: Outbrain },
  { alt: "Revcontent", src: RevContent },
  { alt: "mgid", src: MgId },
]

const HeroSection = () => (
  <section className="hero has-background-dark">
    <div
      className="hero-body animated fadeIn has-text-centered pt-5"
      style={{ opacity: 0 }}
    >
      <h1 className="animated fadeIn title has-text-white has-text-centered has-text-weight-medium">
        AI for Your DSP <br></br>& Native Channels
      </h1>
      <h2 className="animated fadeIn is-hidden-mobile subtitle has-text-white has-text-centered has-text-weight-light is-4 pt-4">
        Trusted by the world’s leading agencies to boost <br></br> Native
        Advertising performance by up to 300%
      </h2>

      <div className="container px-4 my-6">
        <div className="columns">
          <div className="column is-3 hero-col-1 has-text-white is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
            <div className="animated fadeIn ellipse ellipse-1 is-hidden-mobile">
              14 billion+ events
            </div>
            <div className="animated fadeIn ellipse ellipse-1 is-hidden-tablet">
              9+ years engagement data
            </div>
            <div className="animated fadeIn ellipse ellipse-2"></div>
            <div className="animated fadeIn ellipse ellipse-3"></div>
            <p className="animated fadeIn mt-5 is-hidden-mobile">
              9+ years engagement data
            </p>
          </div>
          <TransitionArrow />
          <div
            animateIn="fadeIn"
            className="animated fadeIn column hero-col-2"
            style={{ opacity: 0 }}
          >
            {/* <img className="bubble" src={Bubble} alt="Bubble" /> */}
            <span className="bubble"></span>
            <p className="title is-4 has-text-white m-0">AI</p>
            <img className="star" src={Star} alt="Star" />
          </div>
          <TransitionArrow />
          <div className="column is-3 hero-col-3 is-flex-desktop is-flex-direction-column is-align-items-center is-flex-gap-1">
            {images.map((image, i) => (
              <img
                className="op-0 animated fadeIn"
                src={image.src}
                alt={image.alt}
                style={{ animationDelay: `${0.3 + i * 0.1}s` }}
              />
            ))}
          </div>
        </div>
      </div>

      <Link
        to="#request-demo"
        className="animated fadeIn button is-rounded is-medium is-link"
        style={{ animationDelay: "1s" }}
        target="_blank"
      >
        Request Demo
      </Link>
    </div>
  </section>
)

export default HeroSection
